import React, { useEffect, useState, useRef } from "react"
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Seo } from "../components/seo";
import { FallingStage, Bar } from '@adamatronix/falling';
import { Layout } from "../components/Layout/layout"
import { Scrollable } from '@adamatronix/react-scrollable';
import { StoreContext } from "../context/store-context";
import CreateSidebar from "../components/CreateSidebar/CreateSidebar";
import { fontstack } from '../utils/fontstack';
import { type } from '../utils/type';
import { media } from '../utils/mediaQuery';
import { getMetafields } from "../utils/getMetafields";
import CreateAdd from '../components/CreateAdd/CreateAdd';
import CreateProduct from "../components/CreateProduct/CreateProduct";


const MainWrapper = styled.div` 
  position: relative;
  display: flex;
  ${media.large`
    height: calc(100vh - 160px);
  `}
`

const Pane = styled.div` 
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px;
  overflow: hidden;

  ${media.large`
    width: 50%;
    padding: 30px;
  `}
`

const FixedPane = styled.div` 
  width: 50%;
  box-sizing: border-box;
  padding: 30px;
  height: calc(100vh - 160px);
  position: fixed;
  left: 0;
  top: 160px;
`

const StartMessage = styled.p`
  ${fontstack.default}
  ${type('body')}
  color: #383B23;
  position: absolute;
  z-index: 2;
  top: 50%;
  display: block;
  text-align: center;
  width: 100%;
  pointer-events: none;
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity .2s ease;
`

const FixedPaneInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #383B23;
  border-radius: 20px;
  overflow: hidden;
`

const ProductGrid = styled.div` 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 30px;

  ${media.large`
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    margin-bottom: 25px;
  `}
`

const CreateWrapper = styled.div` 
  position: relative;
  margin: 50px 0;
  display: flex;
  justify-content: center;

  ${media.large`
    margin: 0;
  `}
`

const CreateAddMod = styled(CreateAdd)` 
  max-width: inherit;
`

const SelectPane = styled.div` 
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const SelectPaneTop = styled.div`
  position: relative;
  padding: 0 0 40px;
  border-bottom: 1px solid #383B23;

  ${media.large`
    padding: 0 0 70px;
  `}
`

const SelectPaneInfo = styled.div` 
`

const Info = styled.p` 
  ${fontstack.default}
  ${type('m-body')}
  margin: 15px 0 0;
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}
`

const BoldInfo = styled.span` 
  font-weight: 500;
`

const SelectInfo = styled.ul` 
  list-style: none;
`

const SelectInfoItem = styled.li` 
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}
`

const Title = styled.h1` 
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #383B23;
  margin: 0 0 15px;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
`

const ScrollableWrapper = styled.div` 
  margin: 10px 0 0;
  flex: 1;
  overflow: hidden;
`

export default function CreateYourOwnPage({
  data: { collection }
}) {
  const { createYourOwn, addCreateToCart, loading, width} = React.useContext(StoreContext);
  const [ SidebarActive, setSidebarActive ] = useState(null);
  const [ SidebarProduct, setSidebarProduct ] = useState(null);
  const container = useRef();
  const stage = useRef();
  const products = collection?.products;
  let total = 0;
  let totalAdded = 0;

  if(createYourOwn && products) {
    products.forEach((product)=> {
      const createProduct = createYourOwn[product.id];
      if(createProduct) {
        total += product.priceRangeV2.minVariantPrice.amount * createProduct.qty
        totalAdded += Number(createProduct.qty)
      }
    });
  }

  useEffect(() => {
    if(width < 1080) {
      stage.current = null;
    }
    
    if(!stage.current && createYourOwn && width >= 1080) {
      stage.current = new FallingStage(container.current);

      if(products) {
        products.forEach((product)=> {
          const createProduct = createYourOwn[product.id];
          if(createProduct) {
            const metafields = getMetafields(product);
            const {
              create_your_own_asset
            } = metafields || {};
        
            add(product.id,Number(createProduct.qty),create_your_own_asset.localImage.childImageSharp.gatsbyImageData)
          }
        });
      }
    }
    
    
  }, [width, createYourOwn]);

  const add = (type, amt, asset) => {
    const width = asset.width;
    const height = asset.height;
    const ratio = height / width;

    const scale = 400 / width;

    
    let amtArray = new Array(amt).fill();
    if(stage.current) {
      amtArray.forEach(() => {
        stage.current.add(new Bar(type,400,400*ratio,asset.images.fallback.src,scale));
      })
    }
  }

  const remove = (type,amt) => {
    let amtArray = new Array(amt).fill();
    if(stage.current) {
      amtArray.forEach(() => {
        stage.current.remove(type);
      })
    } 
  }

  const onProductChange = (type,direction,asset) =>{
    if(direction < 0) {
      remove(type,direction*-1);
    } else if (direction > 0) {
      add(type,direction,asset)
    }
  }

  const onSidebarClose = () => {
    setSidebarActive(null);
  }

  return (
    <Layout plp='/create-your-own' excludeFooter squared>
      <Seo
          title='Create Your Own Set'
          description='Nourish with purpose by personalising your set of functional icebar smoothies.'
        />
      <MainWrapper>
        <CreateSidebar active={SidebarActive} product={SidebarProduct} onClose={onSidebarClose}/>
        { width >= 1080 ? <Pane>
          <FixedPane>
            <FixedPaneInner ref={container} >
              <StartMessage active={total === 0 ? true : false}>
              Just start nourishing
              </StartMessage>
            </FixedPaneInner>
          </FixedPane>
        </Pane> : null }
        <Pane>
          <SelectPane>
            <SelectPaneTop>
              <Title>Select your Icebar Smoothies:</Title>
              <SelectInfo>
                <SelectInfoItem>Personalise your own set if you know what your body needs. Please note a minimum order of 10.</SelectInfoItem>
              </SelectInfo>
            </SelectPaneTop>
            <ScrollableWrapper>
              <Scrollable hideTime={2000} autoHide>
                <ProductGrid>
                  { products ? products.map((product)=> {
                      return <CreateProduct product={product} callback={onProductChange} magnifyCallback={(p)=>{setSidebarActive(true); setSidebarProduct(p);} }/>
                  }):null}
                
                </ProductGrid>
             
              </Scrollable>
            </ScrollableWrapper>
            <SelectPaneInfo>
                <CreateWrapper>
                  <CreateAddMod onClick={()=>{ if(!loading){addCreateToCart()} }} disabled={total === 0 || totalAdded < 10 ? true : false} isLoading={loading}>${total}HKD</CreateAddMod>
                </CreateWrapper>
            </SelectPaneInfo>
          </SelectPane>
        </Pane>
      </MainWrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    collection: shopifyCollection(handle: {eq: "icebar-smoothies"}) {
      products {
        ...ProductCard
            metafields {
              key
              value
              localImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 80)
                }
              }
            }
            variants {
              availableForSale
              storefrontId
              title
              price
              selectedOptions {
                name
                value
              }
            }
      }
    }
  }
`
