import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StoreContext } from '../../context/store-context';
import ImageBlock from '../ImageBlock/ImageBlock';
import { LazyBlur } from "@manualengineering/react-lazyblur"; 
import { Quantity } from '../Forms/Forms';
import { getMetafields } from "../../utils/getMetafields";
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import MagnifyIcon from './assets/magnify.svg';

const Wrapper = styled.div`
  position: relative;
  user-select: none;
`

const Title = styled.h2`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;
  color: #383B23;
  margin: 0 0 10px;
  text-transform: uppercase;

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}
`

const BottomWrapper = styled.div`
display: flex;
box-sizing: border-box;
border-radius: 10px;
position: relative;
flex-direction: column;
overflow: hidden;
position: relative;
cursor: pointer;
`

const ImageContainer = styled.div`
position: relative;
width: 100%;
border-radius: 10px;
overflow: hidden;
z-index: 2;
`
const Image = styled.img`
display: block;
width: 100%;
`

const AddWrapper = styled.div`
  position: absolute;
  ${fontstack.default}
  ${type('body')}
  padding: 6px 10px;
  border: 1px solid #383B23;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  bottom: 0;
  z-index: 1;
  background: #F2EADE;
  transition: opacity .2s ease;
  pointer-events: none;
  opacity: 0;

  ${media.large`
    opacity: ${props => props.active ? '0' : '1'};
    margin: -10px 0 0;
    padding: 20px 10px 10px;
  `}
`

const QuantityWrapper = styled.div`
  margin: -10px 0 0;
  padding: 16px 10px 6px;
  background: #383B23;
  z-index: 0;
  
  ${media.large`
    padding: 20px 10px 10px;
  `}
`

const Magnify = styled(MagnifyIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 3;
  cursor: pointer;
`

const CreateProduct = (props) => {
  const { product, callback, magnifyCallback } = props;
  const { addProductToCreate, createYourOwn } = React.useContext(StoreContext);
  const [quantity, setQuantity] = useState(0);
  const [ active, setActive ] = useState(false); 
  const currentVal = useRef(0);
  const {
    id,
    title,
    variants,
    variants: [initialVariant],
    media
  } = product;
  const image = media[0].image.localFile.childImageSharp.gatsbyImageData.images.fallback.src;
  const placeholder = {
    src: media[0].image.localFile.childImageSharp.gatsbyImageData.placeholder.fallback,
    width: media[0].image.localFile.childImageSharp.gatsbyImageData.width,
    height: media[0].image.localFile.childImageSharp.gatsbyImageData.height
  }
  const metafields = getMetafields(product);
  const {
    create_your_own_asset
  } = metafields || {};

  useEffect(()=>{
    const qty = createYourOwn && createYourOwn[id] ? createYourOwn[id].qty : 0;
    setQuantity(qty);
    currentVal.current = qty;

    if(qty > 0) {
      setActive(true);
    }
  },[createYourOwn]) 

  const onChange = (value) => {
    if(value < currentVal.current) {
      callback(id,value - currentVal.current,create_your_own_asset.localImage.childImageSharp.gatsbyImageData);
    } else if (value > currentVal.current) {
      callback(id,value - currentVal.current,create_your_own_asset.localImage.childImageSharp.gatsbyImageData);
    }
    addProductToCreate(id,{qty:value, variantId: initialVariant.storefrontId });
  }

  const onMouseEnter = () =>{
    setActive(true);
  }

  const onMouseLeave = () =>{
    if(Number(quantity) === 0){
      setActive(false);
    }
    
  }


  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Title>{title}</Title>
      <BottomWrapper>
        <Magnify onClick={()=> { if(magnifyCallback){magnifyCallback(product)} }}/>
        <ImageContainer>
          {  image && placeholder ? <ImageBlock src={image} placeholder={placeholder} />  : image ?
            <Image src={image}/>
          : null }
        </ImageContainer>
        <AddWrapper active={active}> Add </AddWrapper>
        <QuantityWrapper>
          <Quantity step='1' min='0' max='99' theme='light' value={quantity} callback={onChange} activeButtons/>
        </QuantityWrapper>
      </BottomWrapper>
    </Wrapper>
  )
}

export default CreateProduct;