import React from 'react';
import styled, { keyframes } from 'styled-components';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { ArrowLinkSmall } from '../ArrowLink/ArrowLink';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.button`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  position: relative;
  ${fontstack.default}
  ${type('m-body')}
  color: ${props => props.disabled ? '#383B23' : '#F3EFD7'};
  background: ${props => props.disabled ? 'transparent' : '#383B23'};
  border: 1px solid #383B23;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: all .2s ease;
  opacity: ${props => props.disabled ? '0.5' : '1'};

  ${media.large`
    ${type('body')}
    padding: 20px 30px;
    max-width: 300px;
  `}
`

const AddButton = styled(ArrowLinkSmall)`
  font-weight: 500;
`


const CreateAdd = (props) => {
  const { children, isLoading, disabled, ...rest } = props;

  return (
    <Wrapper disabled={disabled} {...rest}>
      <LoadingAnimation isLoading={isLoading} theme='light'/>
      {children}
      <AddButton theme={disabled ? 'dark' : 'light'}>ADD</AddButton>
    </Wrapper >
  )

}

export default CreateAdd;