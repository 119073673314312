import React from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { getMetafields } from '../../utils/getMetafields';
import Tag from '../Tag/Tag';
import Icon from './assets/close.svg';
import ImageBlock from '../ImageBlock/ImageBlock';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  overflow: hidden;
`

const SideMenu = styled.div` 
  position: relative;
  height: 100%;
  z-index: 1;
  width: 100%;
  background: #F2EADE;
  padding: 30px 20px 50px;
  box-sizing: border-box;
  transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);

  ${({state}) => state === 'entering' && `
    transform: translate(-100%,0);
  `}

  ${({state}) => state === 'entered' && `
    transform: translate(0%,0);
  `}

  ${({state,pos}) => state === 'exiting' && `
    transform: translate(-100%,0);
  `}

  ${media.large`
    width: 25%;
    padding: 50px 30px;
    border-right: 1px solid #383B23;
  `}
`

const SideMenuInner = styled.div` 
  display: flex;
  flex-direction: column;
`

const BlurLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 0;
  cursor: pointer;

  ${({state}) => state === 'entering' && `
    background: rgba(242,234,222,0);
    backdrop-filter: blur(0px);
  `}

  ${({state}) => state === 'entered' && `
    background: rgba(242,234,222,0.1);
    backdrop-filter: blur(10px);
  `}

  ${({state}) => state === 'exiting' && `
    background: rgba(242,234,222,0);
    backdrop-filter: blur(0px);
  `}
`

const Title = styled.h2`
  ${fontstack.default}
  ${type('m-large')}
  color: #383B23;
  font-weight: 300;
  padding: 0 0 10px;
  border-bottom: 1px solid #383B23;
  margin: 65px 0 30px;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    padding: 0 0 15px;
    margin: 50px 0 30px;
  `}
`

const Nutrition = styled.div` 
  position: relative;
  width: 100%;
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
`
const TopRow = styled.div` 
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const CreateSidebar = (props) => {
  const { active, product, onClose } = props;

  const metafields = product ? getMetafields(product) : null;
  const {
    subtitle,
    create_your_own_nutrition
  } = metafields || {};

  const nutritionImage =  create_your_own_nutrition && create_your_own_nutrition.localImage ? create_your_own_nutrition.localImage.childImageSharp.gatsbyImageData : null;
  const nutritionImageSource = nutritionImage ? nutritionImage.images.fallback.src : null;
  const nutritionImagePlaceholder = nutritionImage ? nutritionImage.placeholder.fallback : null;
  const nutritionImageWidth = nutritionImage ? nutritionImage.width : null;
  const nutritionImageHeight = nutritionImage ? nutritionImage.height : null;

  return (
    <Transition in={active} timeout={{
      enter: 0,
      exit: 200,
     }}>
       {state => { 
         if(state !== 'exited') {
          return (
            <Wrapper> 
              <SideMenu state={state}>
                <SideMenuInner>
                  <TopRow><Tag theme='outline'>{subtitle ? subtitle.value : null}</Tag><CloseIcon onClick={()=>{ if(onClose) { onClose() }}}/></TopRow>
                  <Title>{product.title}</Title>
                  <Nutrition>
                  {nutritionImage ? <ImageBlock src={nutritionImageSource} /> : null }
                  </Nutrition>
                </SideMenuInner>
              </SideMenu>
              <BlurLayer state={state} onClick={()=>{ if(onClose) { onClose() }}}/>
            </Wrapper >
            )
         }
       }}
     </Transition>
  )

  

}

export default CreateSidebar;